/* nav {
  will-change: background-color, color, border-color;
  transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out,
    transform 0.2s ease-in-out;
  background-color: var(--section-background-color);
  color: var(--section-text-color);
  border-color: var(--section-text-color);
  transform: translateY(-100%);
}

nav.nav--is-visible {
  transform: translateY(0);
} */

.sticky-nav-item {
  height: var(--height);
  color: black;
}

.sticky-nav-item > button {
  top: var(--inner-top);
}

.sticky-nav-item button span {
  width: 1.2em;
}

.sticky-nav-item:nth-of-type(1) button span::after {
  content: "➀";
}

.sticky-nav-item:nth-of-type(2) button span::after {
  content: "②";
}

.sticky-nav-item:nth-of-type(3) button span::after {
  content: "➂";
}

.sticky-nav-item:nth-of-type(4) button span::after {
  content: "➃";
}

.sticky-nav-item:nth-of-type(1):hover button span::after,
.sticky-nav-item:nth-of-type(1).sticky-nav-item--active button span::after {
  content: "➊";
}

.sticky-nav-item:nth-of-type(2):hover button span::after,
.sticky-nav-item:nth-of-type(2).sticky-nav-item--active button span::after {
  content: "➋";
}

.sticky-nav-item:nth-of-type(3):hover button span::after,
.sticky-nav-item:nth-of-type(3).sticky-nav-item--active button span::after {
  content: "❸";
}

.sticky-nav-item:nth-of-type(4):hover button span::after,
.sticky-nav-item:nth-of-type(4).sticky-nav-item--active button span::after {
  content: "➍";
}

[data-component="sticky-subnav"] {
  top: var(--top);
}

[data-component="mobile-scrolly-header"] {
  will-change: transform;
  transform: translateY(var(--top));
}

.nav-section-indicator-active > span::after {
  content: "●";
}
/* 
.nav-item-typetester {
  @apply -translate-y-12 transition-transform;
}

.nav-items-sections {
  @apply -translate-x-12 transition-transform;
}

.fonts-loaded .nav-item-typetester,
.fonts-loaded .nav-items-sections {
  @apply translate-y-0 translate-x-0 transition-transform;
} */
