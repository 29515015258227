@charset "UTF-8";
@tailwind base;
@tailwind components;

.viewscripts-sample {
  @apply w-full flex flex-col items-start text-white leading-none whitespace-nowrap mb-9;
}

.viewscripts-sample-size-l {
  @apply text-5xl tracking-tight pt-4;
}

.viewscripts-sample-size-m {
  @apply text-[11vw];
}

.viewscripts-sample-size-s {
  @apply text-[24px] leading-[1.2] md:columns-2 md:gap-6 pt-6 text-justify hyphens-auto md:whitespace-normal md:block;
}

.viewscripts-sample-size-m:lang(th) span:nth-of-type(2) {
  line-height: 1.2;
}

/* class="w-full text-[24px] leading-[1.2] overflow-hidden text-white md:columns-3 md:gap-6 pt-6 text-justify hyphens-auto"
        lang="en"* 

/* Script-specific layout rules */

.viewscripts-sample:lang(ko),
.viewscripts-sample:lang(zh),
.viewscripts-sample:lang(zh-Hans),
.viewscripts-sample:lang(zh-Hant),
.viewscripts-sample:lang(ja) {
  @apply tracking-normal;
}

.viewscripts-sample:lang(ar) {
  @apply leading-[120%];
}

.viewscripts-sample-size-l:lang(hi) {
  @apply leading-[120%] pb-0 mb-0;
}

.viewscripts-sample-size-m:lang(hi) {
  @apply leading-[100%] pb-0 mb-8;
}

.viewscripts-sample-size-l:lang(ko) {
  @apply leading-[105%];
}

.viewscripts-sample-size-m:lang(ko) {
  @apply leading-[115%];
}

.viewscripts-sample-size-s:lang(ko) {
  @apply leading-[120%];
}
