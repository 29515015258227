@charset "UTF-8";

/* --- GT America Intl VAR FONTS ––– */

/* LCGV Proportional Upright, Axes: wdth, wght */

@font-face {
  font-family: "GT America Intl";
  src: url("../../fonts/GT-America-Intl-VF.woff2") format("woff2-variations");
  font-weight: normal;
  font-stretch: 1% 500%; /* Required by Chrome */
}

/* LCGV Proportional Italic, Axes: wdth, wght */

@font-face {
  font-family: "GT America Intl Italic";
  src: url("../../fonts/GT-America-Intl-VF-Italic.woff2")
    format("woff2-variations");
  font-weight: normal;
  font-stretch: 1% 500%; /* Required by Chrome */
}

/* LCGV Mono, Axes: wght */

@font-face {
  font-family: "GT America Intl Mono";
  src: url("../../fonts/GT-America-Intl-VF-Mono.woff2")
    format("woff2-variations");
  font-weight: normal;
}

/* LCGV Mono Italic, Axes: wght */

@font-face {
  font-family: "GT America Intl Mono Italic";
  src: url("../../fonts/GT-America-Intl-VF-Mono-Italic.woff2")
    format("woff2-variations");
  font-weight: normal;
}

/* Arabic, Axes: wght */

@font-face {
  font-family: "GT America Arabic";
  src: url("../../fonts/GT-America-Arabic-VF.woff2") format("woff2-variations");
  font-weight: normal;
}

/* Hebrew, Axes: wght */

@font-face {
  font-family: "GT America Hebrew";
  src: url("../../fonts/GT-America-Hebrew-VF.woff2") format("woff2-variations");
  font-weight: normal;
}

/* Devanagari, Axes: wght */

@font-face {
  font-family: "GT America Devanagari";
  src: url("../../fonts/GT-America-Devanagari-VF.woff2")
    format("woff2-variations");
  font-weight: normal;
}

/* Thai Looped, Axes: wght */

@font-face {
  font-family: "GT America Thai Looped";
  src: url("../../fonts/GT-America-Thai-Looped-VF.woff2")
    format("woff2-variations");
  font-weight: normal;
}

/* Thai Loopless, Axes: wght */

@font-face {
  font-family: "GT America Thai Loopless";
  src: url("../../fonts/GT-America-Thai-Loopless-VF.woff2")
    format("woff2-variations");
  font-weight: normal;
}

/* Korean, Axes: wght */

@font-face {
  font-family: "GT America Korean";
  src: url("../../fonts/GT-America-Korean-VF.woff2") format("woff2-variations");
  font-weight: normal;
}

/* JP / TC / SC, Heavily Subset, Axes: wght */

@font-face {
  font-family: "GT America Japanese";
  src: url("../../fonts/Amer-TPJ-VF-subset.woff2") format("woff2-variations");
  font-weight: normal;
}

@font-face {
  font-family: "GT America TradChinese";
  src: url("../../fonts/Amer-TC-VF-subset.woff2") format("woff2-variations");
  font-weight: normal;
}

@font-face {
  font-family: "GT America SimpChinese";
  src: url("../../fonts/Amer-SC-VF-subset.woff2") format("woff2-variations");
  font-weight: normal;
}

/* Specialty Font, Show Contrast, Axis: CNTR, values 0–100 */

@font-face {
  font-family: "GT America Contrast";
  src: url("../../fonts/specialty/GT-America-cntr-VF.woff2")
    format("woff2-variations");
  font-weight: normal;
}

/* Specialty Font, Show Contrast, Axis: CNTR, values 0–100 */

@font-face {
  font-family: "GT America Mow";
  src: url("../../fonts/specialty/GT-America-mow-VF.woff2")
    format("woff2-variations");
  font-weight: normal;
}

/* Setting up font family variables */

:root {
  --upright-fonts: "GT America Intl", "GT America Arabic", "GT America Hebrew",
    "GT America Devanagari", "GT America Korean", "Comic Sans MS", cursive;
  --italic-fonts: "GT America Intl Italic", "Comic Sans MS", cursive;
  --mono-fonts: "GT America Intl Mono", "Comic Sans MS", cursive;
  --mono-italic-fonts: "GT America Intl Mono Italic", "Comic Sans MS", cursive;
  --thai-looped-fonts: "GT America Thai Looped", "GT America Intl",
    "Comic Sans MS", cursive;
  --thai-loopless-fonts: "GT America Thai Loopless", "GT America Intl",
    "Comic Sans MS", cursive;
}

.italic-fonts {
  font-family: var(--italic-fonts);
}

.mono-fonts {
  font-family: var(--mono-fonts);
}

.mono-italic-fonts {
  font-family: var(--mono-italic-fonts);
}

.thai-looped-fonts {
  font-family: var(--thai-looped-fonts);
}

.thai-loopless-fonts {
  font-family: var(--thai-loopless-fonts);
}

.japanese-fonts {
  font-family: "GT America Japanese";
}

.tradchinese-fonts {
  font-family: "GT America TradChinese";
}

.simpchinese-fonts {
  font-family: "GT America SimpChinese";
}

.specialty-font-contrast {
  font-family: "GT America Contrast";
}

.specialty-font-mow {
  font-family: "GT America Mow";
}

/* GT America Intl (Latin) Proportional */

/* GT America Intl (Latin) Proportional */

.font-std-ultralight {
  font-variation-settings: "wght" 100, "wdth" 100;
}

.font-std-thin {
  font-variation-settings: "wght" 200, "wdth" 100;
}

.font-std-light {
  font-variation-settings: "wght" 300, "wdth" 100;
}

.font-std-regular {
  font-variation-settings: "wght" 400, "wdth" 100;
}

.font-std-medium {
  font-variation-settings: "wght" 500, "wdth" 100;
}

.font-std-bold {
  font-variation-settings: "wght" 700, "wdth" 100;
}

.font-std-black {
  font-variation-settings: "wght" 900, "wdth" 100;
}

.font-std-ultralight-ital {
  font-family: var(--italic-fonts);
  font-variation-settings: "wght" 100, "wdth" 100;
}

.font-std-thin-ital {
  font-family: var(--italic-fonts);
  font-variation-settings: "wght" 200, "wdth" 100;
}

.font-std-ligh-ital {
  font-family: var(--italic-fonts);
  font-variation-settings: "wght" 300, "wdth" 100;
}

.font-std-regular-ital {
  font-family: var(--italic-fonts);
  font-variation-settings: "wght" 400, "wdth" 100;
}

.font-std-medium-ital {
  font-family: var(--italic-fonts);
  font-variation-settings: "wght" 500, "wdth" 100;
}

.font-std-bold-ital {
  font-family: var(--italic-fonts);
  font-variation-settings: "wght" 700, "wdth" 100;
}

.font-std-black-ital {
  font-family: var(--italic-fonts);
  font-variation-settings: "wght" 900, "wdth" 100;
}

.font-cmp-regular {
  font-variation-settings: "wght" 400, "wdth" 50;
}

.font-cmp-medium {
  font-variation-settings: "wght" 500, "wdth" 50;
}

.font-cmp-bold {
  font-variation-settings: "wght" 700, "wdth" 50;
}

.font-cmp-black {
  font-variation-settings: "wght" 900, "wdth" 50;
}

/* Need to add all different widths still */

/* GT America Intl Mono */

.font-mono-ultralight {
  font-family: var(--mono-fonts);
  font-variation-settings: "wght" 100, "ital" 0;
}

.font-mono-thin {
  font-family: var(--mono-fonts);
  font-variation-settings: "wght" 200, "ital" 0;
}

.font-mono-light {
  font-family: var(--mono-fonts);
  font-variation-settings: "wght" 300, "ital" 0;
}

.font-mono-regular {
  font-family: var(--mono-fonts);
  font-variation-settings: "wght" 400, "ital" 0;
}

.font-mono-medium {
  font-family: var(--mono-fonts);
  font-variation-settings: "wght" 500, "ital" 0;
}

.font-mono-bold {
  font-family: var(--mono-fonts);
  font-variation-settings: "wght" 700, "ital" 0;
}

.font-mono-black {
  font-family: var(--mono-fonts);
  font-variation-settings: "wght" 900, "ital" 0;
}

.font-mono-ultralight-italic {
  font-family: var(--mono-fonts);
  font-variation-settings: "wght" 100, "ital" 1;
}

.font-mono-thin-italic {
  font-family: var(--mono-fonts);
  font-variation-settings: "wght" 200, "ital" 1;
}

.font-mono-light-italic {
  font-family: var(--mono-fonts);
  font-variation-settings: "wght" 300, "ital" 1;
}

.font-mono-regular-italic {
  font-family: var(--mono-fonts);
  font-variation-settings: "wght" 400, "ital" 1;
}

.font-mono-medium-italic {
  font-family: var(--mono-fonts);
  font-variation-settings: "wght" 500, "ital" 1;
}

.font-mono-bold-italic {
  font-family: var(--mono-fonts);
  font-variation-settings: "wght" 700, "ital" 1;
}

.font-mono-black-italic {
  font-family: var(--mono-fonts);
  font-variation-settings: "wght" 900, "ital" 1;
}

h1,
.h1 {
  font-family: var(--upright-fonts);
  font-weight: normal;
  font-variation-settings: "wght" 400, "wdth" 100;
  font-weight: normal;
}

h2,
.h2 {
  font-family: var(--upright-fonts);
  font-weight: normal;
  /* @apply text-3xl lg:text-2xl font-ext-bold tracking-tight; */
}

h3,
.h3 {
  font-family: var(--upright-fonts);
  font-weight: normal;
  font-variation-settings: "wght" 400, "wdth" 100;
}

h4,
.h4 {
  font-family: var(--upright-fonts);
  /* font-size: 44px;
  line-height: 48px; */
  /* font-family: var(--upright-fonts);
  font-variation-settings: "wght" 700, "wdth" 150; */
  /* @apply text-md lg:text-xl tracking-tight leading-none pb-6 font-ext-bold; */
}

h5,
.h5 {
  font-family: var(--upright-fonts);
  /* font-size: 34px;
  line-height: 41px; */
}

h6,
.h6 {
  font-family: var(--upright-fonts);
  /* font-size: 24px;
  line-height: 35.52px; */
}

h7,
.h7 {
  font-family: var(--upright-fonts);
  /* font-size: 18px;
  line-height: 21.6px; */
}

section.bg-white p a {
  @apply text-black hover:text-red;
}

section.bg-red p a {
  @apply text-white hover:text-black;
}

section.bg-white p a {
  @apply text-white hover:text-black;
}

nav a {
  @apply hover:text-black;
}

/* Text Styles */

.caps {
  @apply uppercase tracking-[0.02em];
}

.text-mono {
  @apply font-mono-regular;
}

.vf-caption {
  @apply font-mono-regular caps mono-fonts text-xs;
  letter-spacing: 0.06em;
}

.vertical-korean-text {
  writing-mode: vertical-lr;
}

/* OpenType features */

.ot-onum {
  -webkit-font-feature-settings: "kern" 1, "onum" 1, "pnum" 1;
  font-feature-settings: "kern" 1, "onum" 1, "pnum" 1;
}

.ot-onum-tnum {
  -webkit-font-feature-settings: "kern" 1, "onum" 1, "tnum" 1;
  font-feature-settings: "kern" 1, "onum" 1, "tnum" 1;
}

.ot-lnum {
  -webkit-font-feature-settings: "kern" 1, "lnum" 1, "pnum" 1;
  font-feature-settings: "kern" 1, "lnum" 1, "pnum" 1;
}

.ot-lnum-tnum {
  -webkit-font-feature-settings: "kern" 1, "lnum" 1, "tnum" 1;
  font-feature-settings: "kern" 1, "lnum" 1, "tnum" 1;
}

.ot-dlig {
  -webkit-font-feature-settings: "kern" 1, "liga" 1, "dlig" 1;
  font-feature-settings: "kern" 1, "liga" 1, "dlig" 1;
}

.ot-sups {
  text-transform: lowercase;
  -webkit-font-feature-settings: "kern" 1, "sups" 1;
  font-feature-settings: "kern" 1, "sups" 1;
}

.ot-onum-slash {
  -webkit-font-feature-settings: "kern" 1, "onum" 1, "zero" 1;
  font-feature-settings: "kern" 1, "onum" 1, "zero" 1;
}

.ot-lnum-slash {
  -webkit-font-feature-settings: "kern" 1, "lnum" 1, "zero" 1;
  font-feature-settings: "kern" 1, "lnum" 1, "zero" 1;
}

.ot-tf-onum-slash {
  -webkit-font-feature-settings: "kern" 1, "tnum" 1, "onum" 1, "zero" 1;
  font-feature-settings: "kern" 1, "tnum" 1, "onum" 1, "zero" 1;
}

.ot-tf-lnum-slash {
  -webkit-font-feature-settings: "kern" 1, "tnum" 1, "lnum" 1, "zero" 1;
  font-feature-settings: "kern" 1, "tnum" 1, "lnum" 1, "zero" 1;
}

.ot-osf {
  -webkit-font-feature-settings: "onum" 1, "kern" 1;
  font-feature-settings: "onum" 1, "kern" 1;
}

.ot-ssplusarrow {
  -webkit-font-feature-settings: "kern" 1, "ss01" 1, "ss02" 1, "ss03" 1,
    "ss04" 1, "ss05" 1;
  font-feature-settings: "kern" 1, "ss01" 1, "ss02" 1, "ss03" 1, "ss04" 1,
    "ss05" 1;
}

.ot-salt {
  -webkit-font-feature-settings: "calt" 1, "kern" 1;
  font-feature-settings: "calt" 1, "kern" 1;
}

.ot-smcp {
  -webkit-font-feature-settings: "smcp" 1, "kern" 1;
  font-feature-settings: "smcp" 1, "kern" 1;
}

.ot-case {
  -webkit-font-feature-settings: "case" 1, "kern" 1;
  font-feature-settings: "case" 1, "kern" 1;
}

.ot-ss01 {
  -webkit-font-feature-settings: "ss01" 1, "kern" 1;
  font-feature-settings: "ss01" 1, "kern" 1;
}

.ot-ss05 {
  -webkit-font-feature-settings: "ss05" 1, "kern" 1;
  font-feature-settings: "ss05" 1, "kern" 1;
}

.ot-aalt {
  -webkit-font-feature-settings: "aalt" 1, "kern" 1;
  font-feature-settings: "aalt" 1, "kern" 1;
}

.hover-circular-dots:hover {
  -webkit-font-feature-settings: "ss05" 1, "kern" 1;
  font-feature-settings: "ss05" 1, "kern" 1;
}
