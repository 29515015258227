@charset "UTF-8";
@tailwind base;
@tailwind components;

/* body:not(.tt-active) .tt,
body:not(.tt-active) button[data-component="tt-close"] {
  display: none !important;
} */

body:not(.tt-active) .tt,
body:not(.tt-active) button[data-component="tt-close"] {
  @apply -translate-y-[100vh] bg-white;
}

body:not(.tt-active) .tt * {
  @apply pointer-events-none;
}

.tt {
  @apply bg-white overflow-hidden;
  transition: transform 1s, background-color 1s;
}

/* body.tt-teaser .tt {
  @apply -translate-y-[98.5vh] transition-transform duration-100;
} */

/* pt-8 pb-9 */

body.tt-active .tt {
  @apply translate-y-0 pointer-events-auto;
}

[data-component="tt__sample"] {
  @apply mb-6 md:mb-10;
}

.tt-sample {
}

.tt-sample-size-l,
.tt-sample[data-size="l"] {
  @apply text-5xl tracking-tight whitespace-nowrap;
}

.tt-sample-size-m,
.tt-sample[data-size="m"] {
  @apply text-[7vw] leading-[0.95] whitespace-nowrap;
}

.tt-sample-size-s,
.tt-sample[data-size="s"] {
  @apply text-md leading-[135%] max-h-[355px] md:max-h-max md:columns-3 overflow-y-hidden md:overflow-y-auto;
}

[data-component="tt__sample-options"] {
  @apply pt-8 md:pt-0 min-h-[120px] md:min-h-max;
}

/* Script-specific layout rules */

.tt-sample:lang(ko),
.tt-sample:lang(zh),
.tt-sample:lang(zh-Hans),
.tt-sample:lang(zh-Hant),
.tt-sample:lang(ja),
.tt-sample:lang(ar) {
  @apply tracking-normal;
}

.tt-sample:lang(vi)[data-size="l"],
.tt-sample:lang(vi)[data-size="m"] {
  @apply leading-[115%];
}

.tt-sample:lang(vi)[data-size="s"] {
  @apply leading-[130%];
}

.tt-sample:lang(ar)[data-size="l"],
.tt-sample:lang(ar)[data-size="m"] {
  @apply leading-[130%];
}

.tt-sample:lang(ar)[data-size="s"] {
  @apply leading-[145%];
}

.tt-sample:lang(hi)[data-size="l"],
.tt-sample:lang(hi)[data-size="m"] {
  @apply leading-[123%];
}

.tt-sample:lang(hi)[data-size="s"] {
  @apply leading-[135%];
}

.tt-sample:lang(he)[data-size="l"],
.tt-sample:lang(he)[data-size="m"] {
  @apply leading-[115%];
}

.tt-sample:lang(he)[data-size="s"] {
  @apply leading-[130%];
}

.tt-sample:lang(ko)[data-size="l"],
.tt-sample:lang(ko)[data-size="m"] {
  @apply leading-[135%];
}

.tt-sample:lang(ko)[data-size="s"] {
  @apply leading-[145%];
}

.tt-sample:lang(th)[data-size="l"],
.tt-sample:lang(th)[data-size="m"] {
  @apply leading-[120%];
}

.tt-sample:lang(th)[data-size="s"] {
  @apply leading-[145%];
}

.tt-sample.mono-fonts {
  @apply tracking-normal;
}

/* Sliders */

.typetester-slider {
  @apply appearance-none h-1 bg-blue outline-none;
}

.typetester-slider::-webkit-slider-thumb {
  @apply bg-blue appearance-none w-[9px] h-[9px] cursor-pointer rounded-full hover:w-[14px] hover:h-[14px] transition-all;
}

.typetester-slider::-moz-range-thumb {
  @apply bg-blue appearance-none w-[9px] h-[9px] cursor-pointer rounded-full hover:w-[14px] hover:h-[14px] transition-all;
  /* @apply w-[20px] h-[20px] bg-blue cursor-pointer rounded-[2px] hover:bg-white; */
}

/* Dropdowns */

.tt-select-nav-container {
  @apply relative;
}

.tt-select-nav-container::before {
  content: "↓";
  @apply absolute pointer-events-none;
}

.tt-select {
  @apply outline-none appearance-none pl-4 hover:cursor-pointer bg-white;
}

.tt-select:active {
  @apply bg-red;
}

[data-component="tt__sample-options"] [data-show-in-script] {
  visibility: hidden;
  position: absolute;
  pointer-events: none;
  left: -111111px;
}

[data-component="tt__sample-options"][data-script="latin"]
  [data-show-in-script*="latin"] {
  visibility: visible;
  position: unset;
  pointer-events: unset;
  left: unset;
}

[data-component="tt__sample-options"][data-script="thai"]
  [data-show-in-script*="thai"] {
  visibility: visible;
  position: unset;
  pointer-events: unset;
  left: unset;
}

[data-component="tt__sample-options"][data-script="arabic"]
  [data-show-in-script*="arabic"] {
  visibility: visible;
  position: unset;
  pointer-events: unset;
  left: unset;
}

[data-component="tt__sample-options"][data-script="cyrillic"]
  [data-show-in-script*="cyrillic"] {
  visibility: visible;
  position: unset;
  pointer-events: unset;
  left: unset;
}

[data-component="tt__sample-options"][data-script="greek"]
  [data-show-in-script*="greek"] {
  visibility: visible;
  position: unset;
  pointer-events: unset;
  left: unset;
}

[data-component="tt__sample-options"][data-script="vietnamese"]
  [data-show-in-script*="vietnamese"] {
  visibility: visible;
  position: unset;
  pointer-events: unset;
  left: unset;
}

[data-component="tt__sample-options"][data-script="devanagari"]
  [data-show-in-script*="devanagari"] {
  visibility: visible;
  position: unset;
  pointer-events: unset;
  left: unset;
}

[data-component="tt__sample-options"][data-script="hebrew"]
  [data-show-in-script*="hebrew"] {
  visibility: visible;
  position: unset;
  pointer-events: unset;
  left: unset;
}

[data-component="tt__sample-options"][data-script="korean"]
  [data-show-in-script*="korean"] {
  visibility: visible;
  position: unset;
  pointer-events: unset;
  left: unset;
}

[data-component="tt__sample-options"][data-script="thai"]
  [data-show-in-script*="thai"] {
  visibility: visible;
  position: unset;
  pointer-events: unset;
  left: unset;
}

[data-component="tt__wdth-value"]:before {
  content: var(--wdth-text);
}

[data-component="tt__wght-value"]:before {
  content: var(--wght-text);
}

[data-component="tt__sample"] [data-component="tt__sample-text"] {
  font-variation-settings: "wght" var(--wght);
}

[data-component="tt__sample"][data-script="latin"]
  [data-component="tt__sample-text"],
[data-component="tt__sample"][data-script="cyrillic"]
  [data-component="tt__sample-text"],
[data-component="tt__sample"][data-script="greek"]
  [data-component="tt__sample-text"],
[data-component="tt__sample"][data-script="vietnamese"]
  [data-component="tt__sample-text"] {
  font-variation-settings: "wght" var(--wght), "wdth" var(--wdth);
}
