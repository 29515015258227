/* @import "../../node_modules/tailwindcss/base";
@import "../../node_modules/tailwindcss/components";
@import "../../node_modules/tailwindcss/utilities"; */

/* ------ Add your CSS below this line (or @import your other CSS files). ------- */

@import "components/loader.css";
@import "base/grid.css";
@import "base/typography.css";
@import "base/animation.css";
@import "components/nav.css";
@import "components/family-overview.css";
@import "components/type-gallery.css";
@import "components/viewscripts.css";
@import "components/character-set.css";
@import "components/typetester.css";
/* @import "components/intro-style-overview.css";
@import "components/family-overview.css";
@import "components/animated-width.css";
@import "components/extended-subfamily-animation.css"; */

/*
@import "components/axes-overview.css";
@import "components/family-style-overview.css"; */

@tailwind base;
@tailwind components;
@tailwind utilities;

/* NEW CODE */

.font-compressed {
  font-stretch: 50%;
}

/* * {
  box-sizing: border-box;
} */

html {
  --visible-viewport-height: 100vh;
  --global-perspective: 250px;
}

html,
body {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  overflow: auto;
  font-weight: 400;
  font-variation-settings: "wght" 350, "wdth" 100, "ital" 0;
  font-feature-settings: "ss03" 1, "kern" 1;
  font-family: var(--upright-fonts);
}

.content {
  margin-top: 0;
  margin-right: 15px;
  margin-bottom: 0;
  margin-left: 15px;
}
@media (min-width: 768px) {
  .content {
    margin-top: 0;
    margin-right: 25px;
    margin-bottom: 0;
    margin-left: 25px;
  }
}
@media (min-width: 1024px) {
  .content {
    margin-top: 0;
    margin-right: 150px;
    margin-bottom: 0;
    margin-left: 150px;
  }
}

@media (min-width: 1280px) {
  .content {
    margin-top: 0;
    margin-right: 225px;
    margin-bottom: 0;
    margin-left: 225px;
  }
}

/* Comment this out for development because it's annoying to have it locked!! */
/* body.locked {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: var(--visible-viewport-height);
  pointer-events: none;
}

@media (hover: none) {
  body.locked {
    overflow: hidden;
  }
} */

/* body::-webkit-scrollbar {
  width: 15px;
}
body::-webkit-scrollbar-thumb {
  @apply bg-gray hover:bg-green transition-colors;
}
body::-webkit-scrollbar-track {
  @apply bg-black;
  border-left: 1px solid #949494;
} */

.grid-setup {
  @apply grid grid-cols-4 md:grid-cols-12 gap-x-4 md:gap-x-6;
}

body {
  /* defaults for variable fonts vars */
  /* You can overwrite these global defaults using the style attribute of a [data-component*="axes-overview__sample"] */
  --wght: 100;
  --wght-text: "100";
  --wdth: 100;
  --wdth-text: "100";
  --ital: 0;
  --ital-text: "0";
  --CNTR: 100;
  --wght-text: "100";
  --font-size-text: "50";
}

a {
  @apply cursor-pointer;
}

[data-component*="axes-overview__sample"] {
  font-variation-settings: "wght" var(--wght), "wdth" var(--wdth),
    "CNTR" var(--CNTR);
}

[data-component*="axes-overview__wdth-text"]:before {
  content: var(--wdth-text);
}

[data-component*="axes-overview__wght-text"]:before {
  content: var(--wght-text);
}

[data-component*="axes-overview__font-size-text"]:before {
  content: var(--font-size-text);
}

[data-component*="show-hide-section__section"][hidden] {
  display: none;
}
