@charset "UTF-8";
@tailwind base;

.family-overview-table-mobile {
  table-layout: fixed;
  white-space: fixed;
  width: 100%;
  width: calc(100vw - 24px);
  margin-left: 12px;
  @apply select-none;
}

.family-overview-table-desktop {
  table-layout: fixed;
  white-space: fixed;
  @apply select-none;
}

@media only screen and (min-width: 768px) {
  .family-overview-table-desktop {
    /* width: calc(100vw - 50px); */
    width: 100%;
  }
}

@media only screen and (min-width: 1024px) {
  .family-overview-table-desktop {
    /* width: calc(100vw - 300px); */
    width: 100%;
  }
}

@media only screen and (min-width: 1280px) {
  .family-overview-table-desktop {
    width: 100%;
  }
}

.family-overview-table-desktop .active,
.family-overview-table-desktop .active div {
  @apply bg-blue text-white;
}

.family-overview-table-desktop td:hover {
  /* font-family: var(--italic-fonts) !important; */
}

.family-overview-table-desktop td:last-of-type:hover {
  font-family: var(--mono-fonts) !important;
  /* font-style: italic; */
}

td span {
  pointer-events: none;
}

th,
td {
  @apply text-red md:border md:border-blue hover:cursor-pointer;
  font-weight: normal;
}

th,
th div {
  @apply text-blue text-xxs md:text-xs md:leading-[4px] uppercase md:tracking-[0.02em] lg:tracking-[0.06em];
  font-family: "GT America Intl Mono";
}

th div {
  writing-mode: vertical-lr;
  transform: rotate(180deg) translateX(-1em);
  @apply text-center;
}

tr th:first-of-type {
  padding-left: 0;
}

@media only screen and (min-width: 1024px) {
  th div {
    @apply min-h-[2em] md:min-h-[12em] lg:min-h-[8em] xl:min-h-[10em] xxl:min-h-[8em];
  }
}

td {
  @apply text-[7vw] md:text-[4.5vw] lg:text-[4.5vw] xl:text-[4vw] md:text-center;
}

tr:nth-of-type(n + 2) {
  @apply h-[4.5em] md:h-auto;
}

.hide-on-mobile {
  display: none;
}

@media only screen and (min-width: 1100px) {
  .hide-on-mobile {
    display: inline;
  }
}
/* 
.empty-cell {
  border-top: 0px solid black;
  border-left: 0px solid black;
} */

.family-overview-table td {
  will-change: font-variation-settings, font-family;
}

.family-overview-table-mobile td:nth-of-type(1) {
  font-family: var(--upright-fonts);
  --font-variation-wdth: 50;
}

.family-overview-table-mobile td:nth-of-type(2) {
  font-family: var(--upright-fonts);
  --font-variation-wdth: 75;
}

.family-overview-table-mobile td:nth-of-type(3) {
  font-family: var(--upright-fonts);
  --font-variation-wdth: 100;
}

.family-overview-table-mobile td:nth-of-type(4) {
  font-family: var(--upright-fonts);
  --font-variation-wdth: 125;
}

.family-overview-table-mobile td:nth-of-type(5) {
  font-family: var(--upright-fonts);
  --font-variation-wdth: 150;
}

.family-overview-table-mobile td:nth-of-type(6) {
  font-family: var(--mono-fonts);
  --font-variation-wdth: 100;
}

.family-overview-table-mobile tr:nth-of-type(2) {
  --font-variation-wght: 100;
}

.family-overview-table-mobile tr:nth-of-type(3) {
  --font-variation-wght: 200;
}

.family-overview-table-mobile tr:nth-of-type(4) {
  --font-variation-wght: 300;
}

.family-overview-table-mobile tr:nth-of-type(5) {
  --font-variation-wght: 400;
}

.family-overview-table-mobile tr:nth-of-type(6) {
  --font-variation-wght: 500;
}

.family-overview-table-mobile tr:nth-of-type(7) {
  --font-variation-wght: 700;
}

.family-overview-table-mobile tr:nth-of-type(8) {
  --font-variation-wght: 900;
}

.family-overview-table-desktop td:nth-of-type(1) {
  font-family: var(--upright-fonts);
  --font-variation-wdth: 50;
}

.family-overview-table-desktop td:nth-of-type(2) {
  font-family: var(--upright-fonts);
  --font-variation-wdth: 75;
}

.family-overview-table-desktop td:nth-of-type(3) {
  font-family: var(--upright-fonts);
  --font-variation-wdth: 100;
}

.family-overview-table-desktop td:nth-of-type(4) {
  font-family: var(--upright-fonts);
  --font-variation-wdth: 125;
}

.family-overview-table-desktop td:nth-of-type(5) {
  font-family: var(--upright-fonts);
  --font-variation-wdth: 150;
}

.family-overview-table-desktop td:nth-of-type(6) {
  font-family: var(--mono-fonts);
}

.family-overview-table-desktop tr:nth-of-type(2) {
  --font-variation-wght: 100;
}

.family-overview-table-desktop tr:nth-of-type(3) {
  --font-variation-wght: 200;
}

.family-overview-table-desktop tr:nth-of-type(4) {
  --font-variation-wght: 300;
}

.family-overview-table-desktop tr:nth-of-type(5) {
  --font-variation-wght: 400;
}

.family-overview-table-desktop tr:nth-of-type(6) {
  --font-variation-wght: 500;
}

.family-overview-table-desktop tr:nth-of-type(7) {
  --font-variation-wght: 700;
}

.family-overview-table-desktop tr:nth-of-type(8) {
  --font-variation-wght: 900;
}

table td {
  font-family: var(--font-family);
  font-variation-settings: "ital" 0, "wght" var(--font-variation-wght),
    "wdth" var(--font-variation-wdth);
}

table td:nth-of-type(6) {
  font-variation-settings: "ital" 0, "wght" var(--font-variation-wght);
}

/* table.active td {
  font-variation-settings: "ital" 0, "wght" var(--font-variation-wght),
    "wdth" var(--font-variation-wdth);
} */
