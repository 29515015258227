@charset "UTF-8";
@tailwind base;

/* CHARACTER SET GRID */

.character-set-section {
  @apply flex flex-grow flex-col;
}

.character-set-container {
  @apply flex flex-row flex-grow flex-wrap;
}

.charset-single-char {
  @apply flex justify-center items-center;
  box-shadow: 1px 0 0 0 white, 0 1px 0 0 white, 1px 1px 0 0 white,
    1px 0 0 0 white inset, 0 1px 0 0 white inset;
  height: 2em;
  width: calc((100% / 10) - 0.05px) @apply rounded-[6px];
}

@media only screen and (min-width: 768px) {
  .charset-single-char {
    height: 2em;
    width: calc((100% / 10) - 0.05px);
  }
}

@media only screen and (min-width: 1024px) {
  .charset-single-char {
    height: 2em;
    width: calc((100% / 12) - 0.05px);
  }
}

@media only screen and (min-width: 1280px) {
  .charset-single-char {
    height: 2em;
    width: calc((100% / 20) - 0.05px);
  }
  /* .charset-single-char:nth-of-type(20) {
    @apply rounded-tr-[6px];
  } */
}
/* 
.charset-single-char:nth-of-type(1) {
  @apply rounded-tl-[6px];
}

.charset-single-char:nth-last-of-type(4) {
  @apply rounded-br-[6px];
}

.charset-single-char:nth-last-of-type(3) {
  @apply rounded-bl-[6px];
}

.charset-single-char:last-of-type {
  @apply rounded-br-[6px];
} */

.charset-single-char {
  @apply hover:bg-white hover:text-red hover:cursor-pointer;
}

/* CHARSET LOUPE */

.charset-highlight.zoomed {
  @apply block md:hidden bg-white border border-black text-center pointer-events-none transition-opacity ease-in-out w-[200px] h-[200px] md:w-[300px] md:h-[300px] text-[31vw] leading-[1.575] md:text-[22vw] md:leading-[1.575] lg:text-[12vw] lg:leading-[1.375];
}

[data-component="char-set__unicode"]:before {
  content: var(--unicode);
}

[data-component="char-set__script"]:before {
  content: var(--script);
}

[data-component="char-set__subset"]:before {
  content: var(--subset);
}

[data-component="char-set__subset-size"]:before {
  content: var(--subset-size);
}

[data-component="char-set__loupe"]:before {
  content: var(--char);
  font-feature-settings: var(--font-feature-settings);
}

/* Adjusting font size in loupe for specific languages to fit box */

/* Adjustments for Devanagari characters */
[data-component="char-set__loupe"][lang="hi"],
[data-component="char-set__loupe"][lang="mr"],
[data-component="char-set__loupe"][lang="ne"] {
  @apply scale-[0.92];
}

/* Adjustments for Arabic characters */
[data-component="char-set__loupe"][lang="ar"],
[data-component="char-set__loupe"][lang="ku"],
[data-component="char-set__loupe"][lang="ur"],
[data-component="char-set__loupe"][lang="fa"] {
  @apply scale-[0.97];
}

[data-component="char-set__loupe"][lang="thll"]:before {
  font-family: "GT America Thai Loopless";
}

[data-component="char-set__loupe"][lang="thlp"]:before {
  font-family: "GT America Thai Looped";
}
