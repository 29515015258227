@charset "UTF-8";

/* LCGV Proportional Upright, Axes: wdth, wght */

@font-face {
  font-family: "GT America Intl Latin Subset";
  src: url("../../fonts/GT-America-L-VF-subset.woff2")
    format("woff2-variations");
  font-weight: normal;
  font-stretch: 1% 500%; /* Required by Chrome */
}

.font-loader {
  font-family: "GT America Intl Latin Subset";
}

[data-component="sticky-nav-item"] {
  @apply -translate-x-[150px] transition-transform;
}

.is-init [data-component="sticky-nav-item"] {
  @apply translate-x-[0px] transition-transform;
}

.nav-reveal-left {
  @apply -translate-x-[150px] transition-transform;
}

.is-init .nav-reveal-left {
  @apply bottom-4 left-4 translate-x-[0px] transition-transform;
}

[data-component="tt-toggle"] {
  @apply -translate-y-[40px] transition-transform;
}

.is-init [data-component="tt-toggle"] {
  @apply translate-y-[0px] transition-transform;
}

[data-nav-section="concept"] h2 {
  @apply translate-y-[80px] transition-transform;
}

.is-init [data-nav-section="concept"] h2 {
  @apply translate-y-[0px] transition-transform;
}
